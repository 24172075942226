<template>
  <div class="test">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="4" class="button"
          ><el-button type="warning" icon="el-icon-receiving" @click="savemodel"
            >Submit Model</el-button
          ></el-col
        >
        <el-col :span="2" class="button"
          ><el-button type="warning" icon="el-icon-receiving" @click="save"
            >Save</el-button
          ></el-col
        >
      </el-row>
    </el-header>

    <el-container class="container">
      <el-main class="main">
        <div class="ques">
          <div class="title">
            Step 6 Define Pie Chart Areas and Coefficients
          </div>
        </div>
        <el-form ref="form" label-width="100px">
          <el-row>
            <el-col :span="2">
              <p></p>
            </el-col>
            <el-col :span="1">
              <p>Area</p>
            </el-col>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="7">
              <p>LR Name</p>
            </el-col>
            <el-col :span="2">
              <p>Coefficient2</p>
            </el-col>
            <el-col :span="3">
              <p>Value</p>
            </el-col>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="5">
              <p>ML Name</p>
            </el-col>
          </el-row>
          <el-form-item v-for="(item, index) in values" :key="index">
            <el-row>
              <el-col :span="4">
                <el-select v-model="item.area" placeholder="">
                  <el-option
                    v-for="i in allArea"
                    :key="i.id"
                    :label="i.name"
                    :value="i.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="1">
                <p style="line-height: 13px;margin-left:10px"></p>
              </el-col>
              <el-col :span="4">
                <el-input
                  :disabled="true"
                  v-model="item.LR_variable_name"
                ></el-input>
              </el-col>
              <el-col :span="1">
                <p style="line-height: 13px;margin-left:10px">*</p>
              </el-col>
              <el-col :span="2">
                <el-input
                  v-model="item.coefficient2"
                  placeholder="Coefficient for pie chart"
                ></el-input>
              </el-col>
              <el-col :span="1">
                <p></p>
              </el-col>
              <el-col :span="4">
                <el-input
                  type="textarea"
                  :rows="JSON.stringify(item.conditions).length / 18"
                  :font-family="Arial"
                  :disabled="true"
                  v-model="description[index]"
                ></el-input>
              </el-col>
              <el-col :span="1">
                <p></p>
              </el-col>
              <el-col :span="4">
                <el-input
                  :disabled="true"
                  v-model="item.ML_variable_name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";

export default {
  name: "test",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      questions: [],
      values: [],
      description: [],
      qid: this.$route.query.qid,
      Arial: "Arial",
      allArea: [
        {
          id: "0",
          name: "None",
          description: "",
        },
      ],
    };
  },
  components: {},
  methods: {
    save() {
      console.log(this.values);
      for (let i = 0; i < this.values.length; i++) {
        let item = this.values[i];
        if (isNaN(item.area)) console.log(item.area);
        if (item.coefficient2 == "") {
          this.$message.error("Empty coefficient!");
          return;
        }
        if (item.area == "") {
          this.$message.error("Empty area!");
          return;
        }
      }
      if (this.qid != undefined) {
        window.localStorage.setItem(
          `linear_model${this.qid}`,
          JSON.stringify(this.values)
        );
      } else {
        window.localStorage.setItem(
          "linear_model",
          JSON.stringify(this.values)
        );
      }
      this.$message.success("Step 6 Saved!");
    },
    savemodel() {
      if (this.qid == undefined) {
        if (
          window.localStorage.getItem("linear_model") == undefined ||
          window.localStorage.getItem("questionnaire_industry") == undefined ||
          window.localStorage.getItem("model_name") == undefined ||
          window.localStorage.getItem("question_json") == undefined
        ) {
          this.$message.error("Step 1 is not saved!");
          return;
        }
        if (window.localStorage.getItem("step4") == undefined) {
          this.$message.error("Step 4 is not saved!");
          return;
        }
        if (window.localStorage.getItem("step5") == undefined) {
          this.$message.error("Step 5 is not saved!");
          return;
        }
        this.questions = JSON.parse(
          window.localStorage.getItem("linear_model")
        );
        if (this.questions[0].coefficient == "") {
          this.$message.error("Step 2 is not saved!");
          return;
        }
        if (this.questions[0].coefficient2 == "") {
          this.$message.error("Step 6 is not saved!");
          return;
        }

        var questionnaire_id = "";
        var model_id = "";
        var post_data_qn = {
          name: window.localStorage.getItem("questionnaire_name"),
          fk_industry_id: window.localStorage.getItem("questionnaire_industry"),
          fk_company_type_id: window.localStorage.getItem(
            "questionnaire_company"
          ),
          description: window.localStorage.getItem("questionnaire_description"),
          currency: window.localStorage.getItem("questionnaire_currency"),
          questions: window.localStorage.getItem("question_json"),
        };
        if (this.qid != undefined) {
          this.$axios({
            method: "put",
            url: `https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/${this.qid}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: JSON.stringify(post_data_qn),
          }).then((res) => {
            if (res.data.code != 200) {
              this.$message.error(res.data.error);
              console.log(JSON.stringify(post_data_qn));
              return;
            }
            questionnaire_id = res.data.data.id;
            model_id = res.data.data.fk_plugin_id;
            var model_input = [];
            for (let i = 0; i < this.questions.length; i++) {
              model_input.push({
                LR_variable_name: this.questions[i].LR_variable_name,
                conditions: this.questions[i].conditions,
                coefficient: this.questions[i].coefficient,
                coefficient2: this.questions[i].coefficient2,
                area: this.questions[i].area,
                ML_variable_name: this.questions[i].ML_variable_name,
                type: this.questions[i].type,
                value: this.questions[i].value,
              });
            }
            var linear_model = JSON.stringify(model_input);
            var model_info = JSON.stringify({
              modelStatements: JSON.parse(window.localStorage.getItem("step4")),
              modelOutputs: JSON.parse(window.localStorage.getItem("step5")),
            });
            var post_data = {
              name: window.localStorage.getItem("model_name"),
              fk_questionnaire_id: questionnaire_id,
              model_info: model_info,
              linear_model: linear_model,
            };
            this.$axios({
              method: "put",
              url: `https://api.oxvalue.ai/api/plugin/v0.1/plugin/${model_id}/`,
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
              data: post_data,
            }).then((res) => {
              if (res.data.code != 200) {
                this.$message.error("Model creation not successful!");
                console.log(JSON.stringify(post_data));
                return;
              }
              window.localStorage.removeItem("questionnaire_name");
              window.localStorage.removeItem("questionnaire_industry");
              window.localStorage.removeItem("questionnaire_company");
              window.localStorage.removeItem("questionnaire_description");
              window.localStorage.removeItem("question_json");
              window.localStorage.removeItem("step4");
              window.localStorage.removeItem("step5");
              window.localStorage.removeItem("model_name");
              window.localStorage.removeItem("questionnaire");
              window.localStorage.removeItem("linear_model");
              this.$message.success("Model Uploaded");
              this.values = [];
              this.questions = [];
            });
          });
        } else {
          this.$axios({
            method: "post",
            url:
              "https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/",
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: JSON.stringify(post_data_qn),
          }).then((res) => {
            if (res.data.code != 200) {
              this.$message.error(res.data.error);
              console.log(JSON.stringify(post_data_qn));
              return;
            }
            model_id = res.data.data.fk_plugin_id;
            questionnaire_id = res.data.data.id;
            var model_input = [];
            for (let i = 0; i < this.questions.length; i++) {
              model_input.push({
                LR_variable_name: this.questions[i].LR_variable_name,
                conditions: this.questions[i].conditions,
                coefficient: this.questions[i].coefficient,
                coefficient2: this.questions[i].coefficient2,
                area: this.questions[i].area,
                ML_variable_name: this.questions[i].ML_variable_name,
                type: this.questions[i].type,
                value: this.questions[i].value,
              });
            }
            var linear_model = JSON.stringify(model_input);
            var model_info = JSON.stringify({
              modelStatements: JSON.parse(window.localStorage.getItem("step4")),
              modelOutputs: JSON.parse(window.localStorage.getItem("step5")),
            });
            var post_data = {
              name: window.localStorage.getItem("model_name"),
              fk_questionnaire_id: questionnaire_id,
              model_info: model_info,
              linear_model: linear_model,
            };
            this.$axios({
              method: "PUT",
              url: `https://api.oxvalue.ai/api/plugin/v0.1/plugin/${model_id}/`,
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
              data: JSON.stringify(post_data),
            }).then((res) => {
              if (res.data.code != 200) {
                this.$message.error("Model creation not successful!");
                console.log(JSON.stringify(post_data));
                return;
              }
              window.localStorage.removeItem("questionnaire_name");
              window.localStorage.removeItem("questionnaire_industry");
              window.localStorage.removeItem("questionnaire_company");
              window.localStorage.removeItem("questionnaire_description");
              window.localStorage.removeItem("question_json");
              window.localStorage.removeItem("step4");
              window.localStorage.removeItem("step5");
              window.localStorage.removeItem("model_name");
              window.localStorage.removeItem("questionnaire");
              window.localStorage.removeItem("linear_model");
              this.$message.success("Model Uploaded");
              this.values = [];
              this.questions = [];
            });
          });
        }
      } else {
        if (
          window.localStorage.getItem(`linear_model${this.qid}`) == undefined ||
          window.localStorage.getItem(`questionnaire_industry${this.qid}`) ==
            undefined ||
          window.localStorage.getItem(`model_name${this.qid}`) == undefined ||
          window.localStorage.getItem(`question_json${this.qid}`) == undefined
        ) {
          this.$message.error("Step 1 is not saved!");
          return;
        }
        if (window.localStorage.getItem(`step4${this.qid}`) == undefined) {
          this.$message.error("Step 4 is not saved!");
          return;
        }
        if (window.localStorage.getItem(`step5${this.qid}`) == undefined) {
          this.$message.error("Step 5 is not saved!");
          return;
        }
        this.questions = JSON.parse(
          window.localStorage.getItem(`linear_model${this.qid}`)
        );
        if (this.questions[0].coefficient == "") {
          this.$message.error("Step 2 is not saved!");
          return;
        }
        if (this.questions[0].coefficient2 == "") {
          this.$message.error("Step 6 is not saved!");
          return;
        }

        let questionnaire_id = "";
        let model_id = "";
        let post_data_qn = {
          name: window.localStorage.getItem(`questionnaire_name${this.qid}`),
          fk_industry_id: window.localStorage.getItem(
            `questionnaire_industry${this.qid}`
          ),
          fk_company_type_id: window.localStorage.getItem(
            `questionnaire_company${this.qid}`
          ),
          description: window.localStorage.getItem(
            `questionnaire_description${this.qid}`
          ),
          currency: window.localStorage.getItem(
            `questionnaire_currency${this.qid}`
          ),
          questions: window.localStorage.getItem(`question_json${this.qid}`),
        };
        this.$axios({
          method: "put",
          url: `https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/${this.qid}/`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
          data: JSON.stringify(post_data_qn),
        }).then((res) => {
          if (res.data.code != 200) {
            this.$message.error(res.data.error);
            console.log(JSON.stringify(post_data_qn));
            return;
          }
          questionnaire_id = res.data.data.id;
          model_id = res.data.data.fk_plugin_id;
          var model_input = [];
          for (let i = 0; i < this.questions.length; i++) {
            model_input.push({
              LR_variable_name: this.questions[i].LR_variable_name,
              conditions: this.questions[i].conditions,
              coefficient: this.questions[i].coefficient,
              coefficient2: this.questions[i].coefficient2,
              area: this.questions[i].area,
              ML_variable_name: this.questions[i].ML_variable_name,
              type: this.questions[i].type,
              value: this.questions[i].value,
            });
          }
          var linear_model = JSON.stringify(model_input);
          var model_info = JSON.stringify({
            modelStatements: JSON.parse(
              window.localStorage.getItem(`step4${this.qid}`)
            ),
            modelOutputs: JSON.parse(
              window.localStorage.getItem(`step5${this.qid}`)
            ),
          });
          var post_data = {
            name: window.localStorage.getItem("model_name"),
            fk_questionnaire_id: questionnaire_id,
            model_info: model_info,
            linear_model: linear_model,
          };
          this.$axios({
            method: "put",
            url: `https://api.oxvalue.ai/api/plugin/v0.1/plugin/${model_id}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: post_data,
          }).then((res) => {
            if (res.data.code != 200) {
              this.$message.error("Model creation not successful!");
              console.log(JSON.stringify(post_data));
              return;
            }
            window.localStorage.removeItem(`questionnaire_name${this.qid}`);
            window.localStorage.removeItem(`questionnaire_industry${this.qid}`);
            window.localStorage.removeItem(`questionnaire_company${this.qid}`);
            window.localStorage.removeItem(
              `questionnaire_description${this.qid}`
            );
            window.localStorage.removeItem(`question_json${this.qid}`);
            window.localStorage.removeItem(`step4${this.qid}`);
            window.localStorage.removeItem(`step5${this.qid}`);
            window.localStorage.removeItem(`model_name${this.qid}`);
            window.localStorage.removeItem(`questionnaire${this.qid}`);
            window.localStorage.removeItem(`linear_model${this.qid}`);
            this.$message.success("Model Uploaded");
          });
        });
      }
    },
  },
  computed: {},
  created() {
    // Get all area for pie chart
    this.$axios({
      method: "get",
      url: "https://api.oxvalue.ai/api/plugin/v0.1/pie_chart_sector/",
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    })
      .then((res) => {
        if (res.data["code"] == 403) {
          this.$store.dispatch("clear");
          window.localStorage.removeItem("ova_admin_token");
          this.$router.push("/login");
          return;
        }
        for (let i in res.data.data.sector_list) {
          this.allArea.push({
            id: res.data.data.sector_list[i].id,
            name: res.data.data.sector_list[i].name,
            description: res.data.data.sector_list[i].description,
          });
        }
      })
      .then(() => {
        // if there is qid, read this questionnaire's model
        if (this.qid != undefined) {
          if (
            window.localStorage.getItem(`linear_model${this.qid}`) != undefined
          ) {
            this.values = JSON.parse(
              window.localStorage.getItem(`linear_model${this.qid}`)
            );
            for (let i = 0; i < this.values.length; i++) {
              var msg = "";
              for (let j = 0; j < this.values[i].conditions.length; j++) {
                msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`;
                if (j < this.values[i].conditions.length - 1) msg += "\n";
              }
              this.description.push(msg);
            }
          } else {
            this.$axios({
              method: "get",
              url:
                "https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/" +
                this.qid +
                "/",
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
            }).then((res) => {
              let model_id = res.data.data.fk_plugin_id;
              this.$axios({
                method: "get",
                url: `https://api.oxvalue.ai/api/plugin/v0.1/plugin/${model_id}/`,
                headers: {
                  Authorization: window.localStorage.getItem("ova_admin_token"),
                },
              }).then((res2) => {
                this.values = JSON.parse(res2.data.data.linear_model);
                for (let i = 0; i < this.values.length; i++) {
                  for (let j = 0; j < this.allArea.length; j++) {
                    if (
                      this.values[i].area
                        .toLocaleLowerCase()
                        .startsWith(this.allArea[j].name.toLocaleLowerCase())
                    ) {
                      this.values[i].area = this.allArea[j].id.toString();
                      break;
                    }
                  }
                }
                console.log(this.values);
                for (let i = 0; i < this.values.length; i++) {
                  let msg = "";
                  for (let j = 0; j < this.values[i].conditions.length; j++) {
                    msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`;
                    if (j < this.values[i].conditions.length - 1) msg += "\n";
                  }
                  this.description.push(msg);
                }
              });
            });
          }
        }
        // else read what is stored in localstorage (new model)
        else {
          if (window.localStorage.getItem("linear_model") != undefined) {
            this.values = JSON.parse(
              window.localStorage.getItem("linear_model")
            );
            for (let i = 0; i < this.values.length; i++) {
              let msg = "";
              for (let j = 0; j < this.values[i].conditions.length; j++) {
                msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`;
                if (j < this.values[i].conditions.length - 1) msg += "\n";
              }
              this.description.push(msg);
            }
          }
        }
      });
  },
  mounted() {
    // this.timer = setInterval(this.autoSave, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

/* .test .main {
  max-height: 610px;
} */

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
